<template>
  <section class="success-wrapper">
    <div
      v-if="isSuccessIcon"
      class="icon"
    />
    <div
      v-else
      class="icontow"
    />
    <div class="thanks">
      <!-- Thanks for your subscription ! -->
      <div style="color:black">
        {{ isSuccessInfo }}
      </div>
      <div
        class="button"
        @click="checkisSuccess"
      >
        Done
      </div>
    </div>
  </section>
</template>

<script>
import {
  checkSuccess,
  appgoBack
} from '@/utils'
export default {
  data () {
    return {
      isSuccessInfo: '',
      isSuccessIcon: true
    }
  },
  created () {
    if (this.$route.query.issuccess) {
      if (this.$route.query.issuccess === '1') {
        this.isSuccessInfo = 'Payment success. Thank you!'
        this.isSuccessIcon = true
      } else {
        this.isSuccessInfo = 'Payment failed. Please try again.'
        this.isSuccessIcon = false
      }
    }
    if (this.$route.query.success) {
      if (this.$route.query.success === 'true') {
        this.isSuccessInfo = 'Payment success. Thank you!'
        this.isSuccessIcon = true
      } else {
        this.isSuccessInfo = 'Payment failed. Please try again.'
        this.isSuccessIcon = false
      }
    }
    // setTimeout(() => {
    //   paypalOrderSuccess({})
    // }, 3000)
  },
  methods: {
    checkisSuccess () {
      if (this.$route.query.issuccess) {
        checkSuccess(this.$route.query.issuccess, this.$route.query.orderId)
      }
      if (this.$route.query.success) {
        appgoBack()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.button {
      width: 100%;
      margin: 20px auto;
      // min-width: 500PX;
      max-width: 750PX;
      height: 55PX;
      background: #ffc439;
      color: black;
      border-radius: 4PX;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20PX;
      cursor: pointer;
      &.loading {
        opacity: .8;
      }
    }
  .success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .icon {
      width: 180px;
      height: 180px;
      background-image: url('~@/assets/img/success-icon.png');
      background-size: cover;
      margin-bottom: 60px;
    }
  .icontow {
      width: 180px;
      height: 180px;
      background-image: url('~@/assets/img/succss.png');
      background-size: cover;
      margin-bottom: 60px;
    }    .thanks {
      color: #999999;
    }
  }
</style>
